!--
- Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
- Unauthorized copying of this file, via any medium is strictly prohibited
- Proprietary and confidential
-->

<template>
  <feed v-if="!loading" :feed-list-uid="professional.feedList"
        :target-feed-lists-uid="[professional.feedList, space.feedList]"
        :filter-subscriptions-uid="selectedSubscriptionsUid"
  >
    <!--    <template v-slot:subscriptions-filter>-->
    <!--      <div class="text-right">-->
    <!--        <feed-subscriptions-filter v-if="Array.isArray(subscriptions) && subscriptions.length"-->
    <!--                                   v-model="selectedSubscriptionsUid"-->
    <!--                                   :subscriptions="subscriptions"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </template>-->
  </feed>
</template>

<script>
  import Feed from '@/modules/feeds/components/Feed'
  import { Professional, Space } from '@/models'
  // import FeedSubscriptionsFilter from '@/modules/feeds/components/FeedSubscriptionsFilter'
  import { column } from '@/utils/array'
  import { retryablePromises } from '@/utils/promise'

  export default {
    name: 'UserFeed',
    components: {Feed},
    data() {
      return {
        loading: true,
        selectedSubscriptionsUid: null,
        itemsBreadcrumbs: []
      }
    },
    computed: {
      space() {
        return Space.get(this.$store.state.core.space)
      },
      subscriptions() {
        return this.$store.state.feeds.subscriptions ? Object.values(this.$store.state.feeds.subscriptions) : null
      },
      professional() {
        return Professional.get(this.$auth.user.sub)
      }
    },
    async created() {
      try {
        await retryablePromises([
          () => this.$store.dispatch('feeds/fetchSubscriptions'),
          () => this.space.fetchFeedList(),
          () => this.professional.fetchFeedList()
        ], async () => {
          return await this.$confirm('Une erreur est survenue', 'retry')
        })

        this.selectedSubscriptionsUid = column(this.subscriptions, 'uid')
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
</script>
